import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = [
  {
    title: "Sample Layout Samples",
    postId: "70b6d2d276e3",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Layout Samples",
  path: "react-layout-samples",
}

const ReactLayoutSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactLayoutSamples
